import self from "../img/self.png"

// UI/UX
import hisp from "../img/hisp.png"
import guimaraes from "../img/guimaraes.png"
import dreamhive from "../img/dreamhive.png"
import journeylink from "../img/journeylink.png"

// Product Display
import yoki from "../img/yoki.png"
import unoco from "../img/unoco.png"
import inovar from "../img/inovar.png"
import romanato from "../img/romanato.png"
import petbrilho from "../img/petbrilho.png"
import castropil from "../img/castropil.png"
import florestal from "../img/florestal.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

/* 
This variable will change the layout of the website from multipage to single, scrollable page
*/
export let singlePage = false;

/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Arantes",
    lastName: "Arantes",
    initials: "", // the example uses first and last, but feel free to use three or more if you like.
    position: "a digital designer",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '📅',
            text: '22 years old'
        },
        {
            emoji: '🌎',
            text: 'based in brazil'
        },
        {
            emoji: "🎨",
            text: "product designer"
        },
        {
            emoji: "📧",
            text: "email@arantesk.dev",
            link: "mailto:email@arantesk.dev?subject=Let's start talking!"
        }
    ],
    socials: [
        {
            link: "https://instagram.com/arantespgabriel",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/arantesk",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://linkedin.com/in/arantesk",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        }
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Guimarães App",
            link: "https://www.figma.com/design/IexhzCQhWcI1K4CLW3wxFt/Guimar%C3%A3es-App?node-id=533-2",
            image: guimaraes
        },
        {
            title: "HiSP Website",
            link: "https://www.figma.com/design/mBWopjoA5w2UC4gRmjgADr/HiSP?node-id=727-308",
            image: hisp
        },
        {
            title: "DreamHive App",
            link: "https://www.figma.com/design/RJCYYpeDTjjH268KuXyfYR/DreamHive?node-id=416-4180",
            image: dreamhive
        },
        {
            title: "JourneyLink Website",
            link: "https://www.figma.com/design/6DnAc1u1pTqkDcoKKHxCUV/Journey-Link?node-id=917-3",
            image: journeylink
        },
        {
            title: "Yoki",
            image: yoki
        },
        {
            title: "Interbrilho",
            link: "https://www.behance.net/gallery/164899245/Interbrilho",
            image: petbrilho
        },
        {
            title: "Unoco",
            link: "https://www.behance.net/gallery/164960271/Unoco",
            image: unoco
        },
        {
            title: "Castropil",
            image: castropil
        },
        {
            title: "Inovar",
            link: "https://www.behance.net/gallery/164961401/Inovar",
            image: inovar
        },
        {
            title: "Romanato",
            link: "https://www.behance.net/gallery/164961133/Romanato",
            image: romanato,
        },
        {
            title: "Florestal",
            link: "https://www.behance.net/gallery/164883999/Florestal",
            image: florestal
        }



    ]
}