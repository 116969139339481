import React from 'react';
import {Box} from "@mui/material";

function PortfolioBlock(props) {
   const {image, title, link} = props;
   return (
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
         <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Box component={'img'} src={image} alt={'mockup'} sx={ link ? { 
               cursor: 'pointer', borderRadius: '1rem', 
               ":hover": {
                  transform: 'translateY(-3px)', transition: 'all 250ms ease'
               } 
            } : {}} />
         </a>
         <h1 style={{fontSize: '2rem'}}>{title}</h1>
         <Box className={'portfolio'} display={'flex'} flexDirection={'column'} gap={'0.5rem'}
               alignItems={'center'} fontSize={'1.5rem'} py={'2rem'}>
         </Box>
      </Box>
   );
}

export default PortfolioBlock;